import { createSlice } from '@reduxjs/toolkit';

export const snackType = {
  FAILED: 'failed',
  SUCCESS: 'success'
}

const initialState = {
  open: false,
  type: null,
  text: null,
  translate: false,
  props: {}
}

export const snackAlertsSlice = createSlice({
  name: 'snackAlerts',
  initialState,
  reducers: {
    showSuccessBar: (state, action) => {
      state.open = true;
      state.type = snackType.SUCCESS;
      state.text = action.payload;
    },
    showFailedBar: (state, action) => {
      state.open = true;
      state.type = snackType.FAILED;
      state.text = action.payload;
    },
    showSuccessBarT: (state, action) => {
      state.open = true;
      state.type = snackType.SUCCESS;
      state.text = action.payload.text;
      state.props = action.payload.props;
      state.translate = true;
    },
    showFailedBarT: (state, action) => {
      state.open = true;
      state.type = snackType.FAILED;
      state.text = action.payload.text;
      state.props = action.payload.props;
      state.translate = true;
    },
    closeBar: (state) => {
      state.open = false;
      state.type = null;
      state.text = null;
      state.translate = false;
      state.props = {}
    },
  },
});

export const {
  showSuccessBar,
  showFailedBar,
  showSuccessBarT,
  showFailedBarT,
  closeBar,
} = snackAlertsSlice.actions;
