import React from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import { snackType, closeBar } from './snackAlertsSlice'

const useStyles = makeStyles((theme) => ({
  alert: {
    // backgroundColor: theme.palette.black.secondary,
    color: theme.palette.success.darkText,
  },
  alertText: {
    // padding: 10,
    color: theme.palette.success.darkText,
  },
}));

export const SnackSuccess = () => {

  const classes = useStyles()
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const snackState = useSelector((state) => state.snackAlerts)

  const handelClose = () => {
    dispatch(closeBar())
  }

  const open = snackState?.open && snackState?.type === snackType.SUCCESS
  const text = snackState.translate ? t(snackState?.text, snackState.props) : snackState?.text

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handelClose}
    >
      <Alert
        onClose={handelClose}
        icon={false}
        className={classes.alert}
        severity="success"
      >
        <Typography align="left" variant="h6" className={classes.alertText}>
          {text}
        </Typography>
      </Alert>
    </Snackbar>
  )
}
