import React from 'react';

import Grid from '@material-ui/core/Grid'
import { CircularProgress } from '@material-ui/core';

function Loader({ size = 80, fullHeight = false }) {
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ height: fullHeight ? '100vh' : '75vh' }}
    >
      <CircularProgress color="inherit" size={size} />
    </Grid>
  );
}

export default Loader;
