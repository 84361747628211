import 'core-js/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { OidcProvider } from 'redux-oidc'
import SuspenseFallback from './components/common/SuspenseFallback';

import './i18n'
import './index.css'

import store, { persistor } from './app/store'
import App from './app/App'

import userManager from './app/userManager'
import * as serviceWorker from './serviceWorker'

// TODO FIX WARNINGS
console.warn = () => {}

const render = Component => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={<SuspenseFallback/>} persistor={persistor}>
          <OidcProvider store={store} userManager={userManager}>
            <Component />
          </OidcProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  )
}

render(App)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
