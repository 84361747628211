import { createSlice } from '@reduxjs/toolkit';
import { get, post, put, del } from '../../api/api';
import { showFailedBarT, showSuccessBarT } from '../SnackAlerts/snackAlertsSlice';


export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    data: [],
    fetchingUsers: false,
    fetchingUserEdit: false,
    user: null,
    addResult: null,
    editResult: null,
    deletedUser: null,
    signingOut: false,
  },
  reducers: {
    storeUser: (state, action) => {
      state.user = action.payload;
    },
    storeUsers: (state, action) => {
      state.data = action.payload;
      state.fetchingUsers = false;
    },
    setAddResult: (state, action) => {
      state.addResult = action.payload;
    },
    setEditResult: (state, action) => {
      state.editResult = action.payload;
    },
    setDeletedUser: (state, action) => {
      state.deletedUser = action.payload;
    },
    unsetUsersData: (state) => {
      state.data = [];
    },
    setFetchingUsers: (state, action) => {
      state.fetchingUsers = action.payload;
    },
    setFetchingUserEdit: (state, action) => {
      state.fetchingUserEdit = action.payload;
    },
    setSigningOut: (state, action) => {
      state.signingOut = action.payload;
    },
  },
});

export const {
  storeUsers,
  storeUser,
  setAddResult,
  setDeletedUser,
  unsetUsersData,
  setFetchingUsers,
  setSigningOut,
  setFetchingUserEdit,
  setEditResult
} = usersSlice.actions;

export const fetchUsers = (id) => async (dispatch) => {
  try {
    dispatch(setFetchingUsers(true));
    const response = await get(`/Locations/${id}/Users`);
    dispatch(storeUsers(response.data.map((u) => ({ ...u, locationId: id }))));
    dispatch(setFetchingUsers(false));
  } catch (err) {
    dispatch(setFetchingUsers(false));
    console.log(err);
  }
};

export const fetchUser = () => async (dispatch) => {
  try {
    const response = await get(`/Users`);
    dispatch(storeUser(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const addUser = (id, values) => async (dispatch) => {
  try {
    const result = await post(`/Locations/${id}/Users`, {
      email: values.email,
    });
    dispatch(setAddResult(result.status));
    dispatch(fetchUsers(id));
    const text = 'userAddSuccessful'
    dispatch(showSuccessBarT({ text }))
  } catch (err) {
    dispatch(setAddResult(err.status));
    if (err.status === 404) {
      const text = 'userNotFound'
      dispatch(showFailedBarT({ text }))
    } else {
      const text = 'userAlreadyAdded'
      dispatch(showFailedBarT({ text }))
    }
    console.log(err);
  }
};

export const editUser = (locationId, values) => async (dispatch) => {
  try {
    dispatch(setFetchingUserEdit(true));
    await put('/Users', values);
    dispatch(setFetchingUserEdit(false));
    if (locationId !== null) {
      dispatch(fetchUsers(locationId));
    } else {
      dispatch(fetchUser());
    }
  } catch (err) {
    dispatch(setFetchingUserEdit(false));
    const text = 'editingProfileFailed'
    dispatch(showFailedBarT({ text }))
    console.log(err);
    throw err
  }
};

export const removeUser = (locationId, userId) => async (dispatch) => {
  try {
    await del(`/Locations/${locationId}/Users/${userId}`);
    dispatch(setDeletedUser(userId));
    dispatch(fetchUsers(locationId));
  } catch (err) {
    const text = 'userDeletedFromLocation'
    dispatch(showFailedBarT({ text }))
    console.log(err);
  }
};

export default usersSlice.reducer;
