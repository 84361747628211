import React from 'react';

import { SnackFailed } from './SnackFailed'
import { SnackSuccess } from './SnackSuccess'


export const SnackAlerts = () => {
  return (
    <>
      <SnackSuccess/>
      <SnackFailed/>
    </>
  );
}

