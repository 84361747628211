import React, { lazy, Suspense } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import SuspenseFallback from '../components/common/SuspenseFallback';

function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
}

const Login = lazy(() => import('../features/login/Login'));
const LoginCallback = lazy(() => import('../features/login/LoginCallback'));
const Locations = lazy(() => import('../features/locations/Locations'));
const ManageLocations = lazy(() =>
  import('../features/locations/ManageLocations')
);
const Gateways = lazy(() => import('../features/gateways/Gateways'));
const Location = lazy(() => import('../features/location/Location'));
const EditLocation = lazy(() => import('../features/location/EditLocation'));
const Plate = lazy(() => import('../features/plate/Plate'));
const EditPlate = lazy(() => import('../features/plate/EditPlate'));
const PlateGroup = lazy(() => import('../features/group/PlateGroup'));
const EditPlateGroup = lazy(() => import('../features/group/EditPlateGroup'));
const UserDetails = lazy(() => import('../features/users/UserDetails'));
const Profile = lazy(() => import('../features/profile/Profile'));
const EditProfile = lazy(() => import('../features/profile/EditProfile'));
const NotFound = lazy(() => import('../features/navigation/NotFound'));

const Routes = (props) => {
  const { authed } = props;

  return (
    <Suspense fallback={<SuspenseFallback />}>
      <Switch>
        <Route
          exact
          path="/"
          render={() => <Redirect to={{ pathname: '/locations' }} />}
        />
        <Route exact path="/login" component={Login} />
        <Route exact path="/callback" component={LoginCallback} />
        <PrivateRoute
          authed={authed}
          exact
          path="/locations"
          component={Locations}
        />
        <PrivateRoute
          authed={authed}
          exact
          path="/locations/manage"
          component={ManageLocations}
        />
        <PrivateRoute
          authed={authed}
          exact
          path="/gateways"
          component={Gateways}
        />
        <PrivateRoute
          authed={authed}
          exact
          path="/location/:id"
          component={Location}
        />
        <PrivateRoute
          authed={authed}
          exact
          path="/location/:id/edit"
          component={EditLocation}
        />
        <PrivateRoute
          authed={authed}
          exact
          path="/plate/:id"
          component={Plate}
        />
        <PrivateRoute
          authed={authed}
          exact
          path="/plate/:id/edit"
          component={EditPlate}
        />
        <PrivateRoute
          authed={authed}
          exact
          path="/group/:id"
          component={PlateGroup}
        />
        <PrivateRoute
          authed={authed}
          exact
          path="/group/:id/edit"
          component={EditPlateGroup}
        />
        <PrivateRoute
          authed={authed}
          exact
          path="/location/:locationId/users/:userId"
          component={UserDetails}
        />
        <PrivateRoute
          authed={authed}
          exact
          path="/profile"
          component={Profile}
        />
        <PrivateRoute
          authed={authed}
          exact
          path="/profile/edit"
          component={EditProfile}
        />
        <PrivateRoute
          authed={authed}
          path="*"
          component={NotFound}
        />
      </Switch>
    </Suspense>
  );
};

Routes.propTypes = {
  component: PropTypes.element,
};

export default Routes;
