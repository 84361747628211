/* eslint-disable quote-props */
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';

const primaryColor = '#2B255C';
const secondaryColor = '#000';
const black = '#000';
const white = '#FFF';
const grey = '#F7F7F7';
const darkGrey = '#757575';
const lightGrey = '#9E9E9E';
const mediumGrey = '#C2C2C2';
const green = '#4CAF50';
const darkGreen = '#1E4620'
const red = '#F05F10';
const darkRed = '#621B16';
const orange = '#F25930';
const blue = '#103551';

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1232,
      xl: 1920,
      laptop: 1024,
    },
  },
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
      contrastText: '#ffcc00',
    },
    black: {
      main: black,
      secondary: '#1F1F1F',
      disabled: 'rgb(0, 0, 0, 0.38)',
    },
    white: {
      main: white,
    },
    grey: {
      main: grey,
      secondary: '#616161',
      skidDivider: '#E0E0E0',
      light: '#E6E6E6',
    },
    darkGrey: {
      main: darkGrey,
    },
    lightGrey: {
      main: lightGrey,
      secondary: '#BDBDBD',
    },
    mediumGrey: {
      main: mediumGrey,
    },
    background: {
      grey: grey,
      mediumGrey: mediumGrey,
      white: white,
      default: '#CDD5DC',
    },
    success: {
      main: green,
      darkText: darkGreen,
      text: '#1E4620',
      background: '#EDF7ED'
    },
    red: {
      main: red,
    },
    orange: {
      main: orange,
    },
    blue: {
      main: blue,
      light: '#9BABB9',
      lighter: '#CDD5DC',
      lightest: '#EDF1F4',
      dark: '#7C8C9A',
    },
    error: {
      main: '#F05F10',
      light: '#F88078',
      dark: '#E31B0C',
    },
    warning: {
      main: '#FF9800',
      text: '#621B16',
      darkText: darkRed,
      background: '#FEECEB'
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Montserrat',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiRadio: {
      root: {
        color: blue,
      },
      colorSecondary: {
        '&$checked': {
          color: blue,
        },
      },
    },
  },
});

theme.typography.h1 = {
  fontFamily: 'Montserrat',
  fontSize: 36,
  lineHeight: '42px',
  color: blue,
  fontWeight: 600,
  [theme.breakpoints.down('xs')]: {
    fontSize: 24,
    lineHeight: '30px',
  },
};

theme.typography.h2 = {
  fontFamily: 'Montserrat',
  fontSize: '28px',
  lineHeight: '32px',
  fontWeight: 500,
  color: blue,
  [theme.breakpoints.down('xs')]: {
    fontSize: '22px',
    lineHeight: '26px',
  },
};

theme.typography.h3 = {
  fontFamily: 'Montserrat',
  fontSize: '20px',
  fontWeight: 600,
  color: '#757575',
  lineHeight: '22px',
  opacity: '0.9',
  wordSpacing: 1,
  letterSpacing: 1,
  [theme.breakpoints.down('xs')]: {
    fontSize: '16px',
  },
};

theme.typography.h4 = {
  fontFamily: 'Montserrat',
  fontSize: 15,
  color: blue,
  fontWeight: 400,
  lineHeight: '14px',
  [theme.breakpoints.down('xs')]: {
    fontSize: 13,
    lineHeight: '12px',
  },
};

theme.typography.h5 = {
  fontFamily: 'Montserrat',
  fontSize: 14,
  color: blue,
  fontWeight: 600,
  lineHeight: '16px',
  [theme.breakpoints.down('xs')]: {
    fontSize: 12,
    lineHeight: '14px',
  },
};

theme.typography.h6 = {
  fontFamily: 'Montserrat',
  fontSize: 16,
  color: blue,
  fontWeight: 600,
  lineHeight: '24px',
  [theme.breakpoints.down('xs')]: {
    fontSize: 14,
  },
};

theme.typography.body1 = {
  fontFamily: 'Montserrat',
  fontSize: 20,
  color: blue,
  fontWeight: 700,
  lineHeight: '20px',
  wordSpacing: 2,
  [theme.breakpoints.down('xs')]: {
    fontSize: 18,
    lineHeight: '18px',
  },
};

theme.typography.body2 = {
  fontFamily: 'Montserrat',
  fontSize: 20,
  color: blue,
  fontWeight: 500,
  lineHeight: '20px',
  wordSpacing: 2,
  [theme.breakpoints.down('xs')]: {
    fontSize: 16,
    lineHeight: '18px',
  },
};

theme.typography.caption = {
  fontFamily: 'Montserrat',
  fontSize: 12,
  color: darkGrey,
  fontWeight: 500,
  lineHeight: '14px',
  wordSpacing: 2,
  [theme.breakpoints.down('xs')]: {
    fontSize: 11,
    lineHeight: '18px',
  },
};

theme.typography.overline = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: '15px',
  [theme.breakpoints.down('xs')]: {
    fontSize: 11,
    lineHeight: '18px',
  },
};

export default theme;
