import { createSlice } from '@reduxjs/toolkit';

export const selectionsSlice = createSlice({
  name: 'selections',
  initialState: {
    selectedTab: 0, // Plates = 0. If Gateways view is added back make this 1.
  },
  reducers: {
    storeSelectedTab(state, action) {
      state.selectedTab = action.payload;
    },
  },
});

export const {
  storeSelectedTab,
} = selectionsSlice.actions;

export const selectTab = (value) => (dispatch) => {
  dispatch(storeSelectedTab(value));
};

export default selectionsSlice.reducer;
