import React from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import theme from './theme';
import Routes from './Routes';
import { setToken } from '../api/api';
import { fetchUser } from '../features/users/usersSlice';

import { SnackAlerts } from '../features/SnackAlerts/SnackAlerts'

function App() {
  const dispatch = useDispatch();
  const authed = useSelector((state) => state.oidc.user) !== null;
  const token = useSelector(
    (state) => state.oidc.user && state.oidc.user.access_token
  );

  useEffect(() => {
    if (token) {
      setToken(token);
      dispatch(fetchUser());
    }
  }, [token]);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <CssBaseline />
        <Container maxWidth="xl" disableGutters>
          <Routes authed={authed} />
          <SnackAlerts />
        </Container>
      </Router>
    </ThemeProvider>
  );
}

export default App;
