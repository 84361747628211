import { createSlice } from '@reduxjs/toolkit';
import { get, post, put, del } from '../../api/api';

import {
  showFailedBarT,
  showSuccessBarT,
} from '../SnackAlerts/snackAlertsSlice';

const initialState = {
  data: [],
  locationId: null,
  fetching: false,
  deleting: false,
  deleteStatus: null,
  newLocationId: null,
}

export const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    fetchAllStart: (state) => {
      state.fetching = true;
      state.fetchError = null;
    },
    fetchAllSuccess: (state, action) => {
      state.fetching = false;
      state.data = action.payload;
    },
    fetchAllError: (state, action) => {
      state.fetching = false;
      state.fetchError = action.payload;
    },
    fetchSingleStart: (state, action) => {
      state.fetching = true;
      state.fetchError = null;
      state.locationId = action.payload;
    },
    deletingLocation: (state, action) => {
      state.deleting = action.payload;
    },
    setDeleteLocationStatus: (state, action) => {
      state.deleteStatus = action.payload;
    },
    storeLocation: (state, action) => {
      state.data = state.data
        .filter((l) => l.locationId !== state.locationId)
        .concat(action.payload);
    },
    storeNewLocation: (state, action) => {
      state.data = state.data.concat(action.payload);
      state.newLocationId = action.payload?.locationId
    },
    resetNewLocationId: (state) => {
      state.newLocationId = null
    },
  },
});

export const {
  fetchAllStart,
  fetchAllSuccess,
  fetchAllError,
  storeLocation,
  storeNewLocation,
  resetNewLocationId,
  fetchSingleStart,
  fetchSingleSuccess,
  fetchSingleError,
  deletingLocation,
  setDeleteLocationStatus
} = locationsSlice.actions;

export const fetchLocations = () => async (dispatch) => {
  try {
    dispatch(fetchAllStart());
    const response = await get('/locations');
    dispatch(fetchAllSuccess(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const fetchLocation = (id) => async (dispatch) => {
  try {
    dispatch(fetchSingleStart(id));
    const response = await get(`/locations/${id}`);
    dispatch(storeLocation(response.data));
  } catch (err) {
    console.log(err);
  }
};

// eslint-disable-next-line no-unused-vars
export const addLocation = (values) => async (dispatch) => {
  try {
    const response = await post('/locations', values);
    dispatch(storeNewLocation(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const editLocation = (values) => async (dispatch) => {
  try {
    const { id, newName } = values;
    await put(`/locations/${id}`, { name: newName });
    dispatch(fetchLocations());
  } catch (err) {
    console.log(err);
  }
};

export const deleteLocation = (id) => async (dispatch) => {
  try {
    dispatch(deletingLocation(true));
    await del(`/locations/${id}`);
    dispatch(deletingLocation(false));
    const text = 'deletingLocationSuccess'
    dispatch(showSuccessBarT({ text }));
    dispatch(fetchLocations());
  } catch (err) {
    const text = 'deletingLocationFailed'
    dispatch(showFailedBarT({ text }))
    dispatch(deletingLocation(false));
    console.log(err);
    throw err
  }
};

export default locationsSlice.reducer;
