import { combineReducers } from '@reduxjs/toolkit';
import { reducer as oidcReducer } from 'redux-oidc';

import locationsReducer from '../features/locations/locationsSlice';
import gatewaysReducer from '../features/gateways/gatewaysSlice';
import usersReducer from '../features/users/usersSlice';
import selectionsReducer from '../features/selections/selectionsSlice';
import { snackAlertsSlice } from '../features/SnackAlerts/snackAlertsSlice';
import { plateConfigsSlice } from '../slicers/plateConfigsSlice';

const rootReducer = () =>
  combineReducers({
    oidc: oidcReducer,
    locations: locationsReducer,
    gateways: gatewaysReducer,
    users: usersReducer,
    selections: selectionsReducer,
    snackAlerts: snackAlertsSlice.reducer,
    plateConfigs: plateConfigsSlice.reducer,
  });

export default rootReducer;
