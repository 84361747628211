import { createSlice } from '@reduxjs/toolkit';
import { get, post, put, del } from '../api/api';

import {
  showFailedBarT,
  showSuccessBarT,
} from '../features/SnackAlerts/snackAlertsSlice';

export const plateConfigsSlice = createSlice({
  name: 'plateConfigs',
  initialState: {
    data: [],
    fetching: false,
    // fetchError: null,
    locationId: null,
    plateAddStatus: null,
    plateRemoveStatus: null,
    redirect: {
      id: null,
      type: null,
    },
    deletedGroup: {
      deleted: false,
      name: '',
    },
  },
  reducers: {
    fetchStart: (state, action) => {
      state.fetching = true;
      state.locationId = action.payload;
    },
    storePlateConfigs(state, action) {
      state.fetching = false;
      state.data = state.data
        .filter((g) => g.locationId !== state.locationId)
        .concat(action.payload);
    },
    storePlateConfig(state, action) {
      state.fetching = false;
      state.data = state.data
        .filter(
          (g) => g.plateConfigurationId !== action.payload.plateConfigurationId
        )
        .concat(action.payload);
    },
    setPlateAddStatus(state, action) {
      state.plateAddStatus = action.payload;
    },
    setPlateRemoveStatus(state, action) {
      state.plateRemoveStatus = action.payload;
    },
    setDeletedGroup: (state, action) => {
      state.deletedGroup = action.payload;
    },
    setPlateConfigRedirect: (state, action) => {
      state.redirectId.id = action.payload.id;
      state.redirectId.type = action.payload.type;
    },
  },
});

export const {
  fetchStart,
  storePlateConfigs,
  storePlateConfig,
  setPlateAddStatus,
  setPlateRemoveStatus,
  setDeletedGroup,
  unsetGroupData,
  setPlateConfigRedirect,
} = plateConfigsSlice.actions;

export const fetchPlateConfigs = (id) => async (dispatch) => {
  try {
    dispatch(fetchStart(id));
    const response = await get(`/PlateConfigurations?locationId=${id}`);
    dispatch(storePlateConfigs(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const fetchPlateConfig = (id) => async (dispatch) => {
  try {
    dispatch(fetchStart(id));
    const response = await get(`/PlateConfigurations/${id}`);
    dispatch(storePlateConfig(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const addPlateConfig = (id, config) => async (dispatch) => {
  try {
    const result = await post(`/PlateConfigurations`, {
      ...config,
      locationId: id,
      isGroup: true,
    });
    dispatch(storePlateConfig(result.data));
    // Redirect to plate page?
    // dispatch(setPlateConfigRedirect(result.data.plateGroupId));
  } catch (err) {
    console.log(err);
  }
};
export const addPlateConfigSingle = (id, config) => async (dispatch) => {
  try {
    const result = await post(`/PlateConfigurations`, {
      ...config,
      locationId: id,
      isGroup: false,
    });
    dispatch(storePlateConfig(result.data));
    // Redirect to plate page?
    // dispatch(setPlateConfigRedirect(result.data.plateGroupId));
  } catch (err) {
    const text = 'plateAddFailed';
    dispatch(showFailedBarT({ text }));
    console.log(err);
  }
};

export const editPlateConfig = (id, values) => async (dispatch) => {
  try {
    const result = await put(`/PlateConfigurations/${id}`, values);
    dispatch(storePlateConfig(result.data));
  } catch (err) {
    console.log(err);
  }
};

export const addPlateToConfig = (locationId, serial) => async (dispatch) => {
  try {
    await post(
      `/PlateConfigurations/${locationId}/Plates?serialNumber=${serial}`
    );
    dispatch(showSuccessBarT({ text: 'plateCreated' }));
    dispatch(fetchPlateConfig(locationId));
  } catch (err) {
    dispatch(showFailedBarT({ text: 'plateAddFailed' }));
    console.log(err);
  }
};

export const deletePlateConfig =
  (id, locationId, name, isGroup) => async (dispatch) => {
    try {
      await del(`/PlateConfigurations/${id}`);
      dispatch(fetchPlateConfigs(locationId));

      if (isGroup) {
        const text = 'groupDeleted';
        const props = { group: name };
        dispatch(showSuccessBarT({ text, props }));
      } else {
        const text = 'plateDeleted';
        const props = { plate: name };
        dispatch(showSuccessBarT({ text, props }));
      }
    } catch (err) {
      if (isGroup) {
        dispatch(showFailedBarT({ text: 'groupDeleteFailed' }));
      } else {
        dispatch(showFailedBarT({ text: 'plateDeleteFailed' }));
      }
      console.log(err);
      throw err;
    }
  };

export const removePlateFromConfig = (id, values) => async (dispatch) => {
  try {
    await del(`/PlateConfigurations/${id}/Plates/${values.serialNumber}`);
    dispatch(showSuccessBarT({ text: 'plateRemoved' }));
    dispatch(fetchPlateConfig(id));
  } catch (err) {
    dispatch(showFailedBarT({ text: 'plateRemoveFailed' }));
    console.log(err);
  }
};

export const setPlateConfigMonitoring =
  (id, isMonitoring) => async (dispatch) => {
    try {
      const result = await post(`/PlateConfigurations/${id}/SetMonitoring`, {
        plateConfigurationId: id,
        isMonitoring,
      });
      dispatch(storePlateConfig(result.data));
    } catch (err) {
      const text = 'monitoringFailed';
      dispatch(showFailedBarT({ text }));
      console.log(err);
    }
  };

export const initZeroing = (id) => async (dispatch) => {
  try {
    const result = await post(`/PlateConfigurations/${id}/ActivateZeroing`, {
      plateConfigurationId: id,
    });
    dispatch(storePlateConfig(result.data));
  } catch (err) {
    const text = 'zeroingFailed';
    dispatch(showFailedBarT({ text }));
    console.log(err);
    throw err;
  }
};
