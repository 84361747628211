import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { loadUser } from 'redux-oidc';

import rootReducer from './rootReducer';
import userManager from './userManager';

const root = rootReducer();

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['oidc'],
};

const persistedReducer = persistReducer(persistConfig, root);

const defaultMiddlewareConfig = {
  serializableCheck: false,
};

const middleware = [...getDefaultMiddleware(defaultMiddlewareConfig)];

const store = configureStore({
  reducer: persistedReducer,
  middleware,
});

export const persistor = persistStore(store);

loadUser(store, userManager);

export default store;
