import axios from 'axios'
import { shallowEqual, useSelector } from 'react-redux'

const {
  REACT_APP_API_URL,
} = process.env;

const instance = axios.create({ baseURL: REACT_APP_API_URL });

export const getToken = async () => {
  const { access_token } = useSelector(state => state.user, shallowEqual)
  return access_token
}

export const setToken = async (token) => {
  instance.defaults.headers.common.Authorization = `Bearer ${token}`
}

const apiRequest = async (path, method, data = null) => {
  return instance.request({
    url: path,
    method,
    data
  })
    .then(res => res)
    .catch(err => {
      console.log('Request failed with error message:', err.message)
      return Promise.reject(err.response)
    })
}

export const get = async (path) => apiRequest(path, 'GET')

export const post = async (path, data) => apiRequest(path, 'POST', data)

export const del = async (path, data = null) => apiRequest(path, 'DELETE', data)

export const put = async (path, data) => apiRequest(path, 'PUT', data)

export const patch = async (path, data) => apiRequest(path, 'PATCH', data)
