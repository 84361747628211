/* eslint-disable max-len */
/* eslint-disable no-undef */
import { WebStorageStateStore } from 'oidc-client'
import { createUserManager } from 'redux-oidc'

const {
  REACT_APP_USER_MANAGER_CLIENT_ID,
  REACT_APP_USER_MANAGER_SCOPE,
  REACT_APP_USER_MANAGER_AUTHORITY,
} = process.env;

const userManagerConfig = {
  client_id: REACT_APP_USER_MANAGER_CLIENT_ID,
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback`,
  response_type: 'token',
  scope: REACT_APP_USER_MANAGER_SCOPE,
  authority: REACT_APP_USER_MANAGER_AUTHORITY,
  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew.html`,
  post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/login`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  userStore: new WebStorageStateStore({ store: localStorage })
}

const userManager = createUserManager(userManagerConfig)

export default userManager
