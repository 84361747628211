import { createSlice } from '@reduxjs/toolkit';
import { get, post, put, del } from '../../api/api';

export const gatewaysSlice = createSlice({
  name: 'gateways',
  initialState: {
    data: [],
    isFetching: false,
    fetchError: null,
    deleteError: null,
    selectedGateway: null,
  },
  reducers: {
    fetchStart: (state) => {
      state.isFetching = true;
      state.fetchError = null;
    },
    fetchSuccess: (state, action) => {
      state.isFetching = false;
      state.data = action.payload;
    },
    fetchError: (state, action) => {
      state.isFetching = false;
      state.fetchError = action.payload;
    },
    deleteSuccess: (state) => {
      state.deleteError = null;
    },
    deleteError: (state, action) => {
      state.deleteError = action.payload;
    },
    storeSelectedGateway: (state, action) => {
      state.selectedGateway = action.payload;
    },
  },
});

export const {
  fetchStart,
  fetchSuccess,
  fetchError,
  deleteSuccess,
  deleteError,
  storeSelectedGateway,
} = gatewaysSlice.actions;

export const fetchGateways = () => async (dispatch, getState) => {
  try {
    const { selectedLocationId } = getState().selections;
    dispatch(fetchStart());
    const response = await get(`/gateways/location/${selectedLocationId}`);
    dispatch(fetchSuccess(response.data));
  } catch (err) {
    dispatch(fetchError(err));
    console.log(err);
  }
};

export const addGateway = (values) => async (dispatch, getState) => {
  try {
    const { selectedLocationId } = getState().selections;
    const response = await post('/gateways', { ...values, locationId: selectedLocationId });
    dispatch(storeSelectedGateway(response.data));
    dispatch(fetchGateways());
  } catch (err) {
    console.log(err);
  }
};

export const editGateway = (values) => async (dispatch) => {
  try {
    const gatewayId = values.selectedGateway.gatewayId;
    await put(`/gateways/${gatewayId}`, { name: values.newName });
    dispatch(fetchGateways());
  } catch (err) {
    console.log(err);
  }
};

export const deleteGateway = (values) => async (dispatch) => {
  try {
    const gatewayId = values.selectedGateway.gatewayId;
    await del(`/gateways/${gatewayId}`);
    dispatch(fetchGateways());
    dispatch(deleteSuccess());
  } catch (err) {
    dispatch(deleteError(err));
    console.log(err);
  }
};

export default gatewaysSlice.reducer;
